import React from 'react'
import { socials } from '../data/links'

import colin from '../../static/colin_head.png'

export const AboutSidebar = () => {
  return (
    <aside className="post-sidebar">
      <div className="about-sidebar-card">
        <img src={colin} alt="Colin" />
        <span>Colin Birkenbihl</span>
      </div>
      <div className="post-sidebar-socials">
      <p> Follow me on:</p>
        <nav>
          {socials.map((link) => (
            <a
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              key={link.url}
            >
              <img src={link.icon} alt={link.label} />
              <span>{link.label}</span>
            </a>
          ))}
        </nav>
      </div>
    </aside>
  )
}
